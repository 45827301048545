
import Vue, { PropType } from 'vue';
import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default Vue.extend({
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      required: true,
      type: Object as PropType<any>,
      default() {
        return {};
      }
    },
    options: {
      required: false,
      type: Object as PropType<any>
    }
  },
  mounted() {
    (this as any).renderChart(this.chartData, this.options);
  }
});
